import * as React from "react"
import { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import styled from "styled-components"
import Layout from '../../components/Layout'
import Post from '../../components/Post'
import DateHeader from '../../components/DateHeader'


const Updates = styled.ul`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`
const Update = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  border: 1px solid var(--color-text);
  div {
    margin: 0.25rem;
    padding: 0.75rem;
  }
`
const Label = styled.div`
  font-weight: bold;
`

const Pill = styled.div`
  border-radius: 15px;
`

const Available = styled(Pill)`
  background-color: var(--color-success);
  color: var(--color-background);
`

const Unavailable = styled(Pill)`
  background-color: var(--color-error);
  color: var(--color-background);
`

const Log = ({ data }) => {
  console.log(2, data)
  //const data = node.data.node.data
  const isAvailable = (key) => {
    const available = key in data && data[key] === true;
    return (
      <div>
        <Label>{key}</Label>
        {available ? <Available>{key} Available</Available> : <Unavailable>{key} Unavailable</Unavailable>}
      </div>
    )
  }

  const keys = ['Internet', 'Power']
  const timestamp = new Date(data.Timestamp)
  return (
    <Update>
      <div><Label>Time</Label><div>{timestamp.toLocaleDateString()} {timestamp.toLocaleTimeString()}</div></div>
      {keys.map(key => isAvailable(key))}
      <div><Label>Send method</Label><div>{'SMS' in data && data.SMS !== null && data.SMS === true ? 'SMS' : 'Internet'}</div></div>
      <div><Label>Note</Label><div>{data.Notes}</div></div>
    </Update>
  )
}

const HurricanePost = ({ data }) => {
  const latestId = data.allAirtable.edges[0].node.data.ID
  const [nodes, setNodes] = useState(data.allAirtable.edges.map(x => x.node.data))

  useEffect(() => {
    const url = "https://api.airtable.com/v0/appUH2hjprVOUHRqf/Updates?" + new URLSearchParams({
      filterByFormula: `ID > ${latestId}`,
    });

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`
          }
        });
        const json = await response.json();
        const result = json.records.map(x => x.fields);
        setNodes([...result, ...nodes]);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout pageTitle="Status Updates">
      <Post>
        <DateHeader>2023-09-15</DateHeader>
        <h1>On hurricanes and decisions.</h1>
        <p>
          About 11 months ago, Hurricane Ian was barrelling towards Florida, with a distinct possibility of making landfall in Tampa Bay.
          As our home was only a few houses up from the evacuation zone, we decided to ride out the storm with my in-laws (0.5 miles away, but in a much better zone and in a sturdier house).
        </p>
        <p>
          Thankfully for our area, we were spared the worst of the storm. In our case, the only real impact was being without power for some hours.
          Anticipating that, I rushed to put together a little status update page that would automatically update when I had internet, with a manual SMS fallback option for updates when I was without.
          It was a fun little build, with Airtable as a database, Twilio to handle the SMS updates, Apple Shortcuts to handle the automatic updates, and Gatsby/React for the front-end.
        </p>
        <p>
          When I first started writing this post, we had just moved a planned trip to Maine up by a week to avoid any possible impact from Hurricane Idalia.
          Tampa once again avoided the worst of it (a few more of these lucky misses and I may seriously believe that MacDill Air Force Base has a secret hurricane deflector).
          I'm still happy with the decision, although I freely admit that driving up the entirety of the East coast with a 20-month old is...inadvisable.
          We were faced with potential bad outcomes that ranged from minor inconvenience to major harm, and we traded that uncertainty for a guaranteed moderate amount of stress (doing all our packing in 24 hours instead of a week).
        </p>
        <p>
          In general thats how I like to approach decisions—identify the available options and their potential outcomes, figure out how much
          uncertainty I'm willing to deal with based on the mix of negative and postive outcomes, and then make my choice.
          I think we all do this subconsciously to some degree; you wear a seatbelt even though the risk of getting in a car crash is relatively
          low for any individual trip because the negative outcome of getting into a car crash without a seatbelt on is very bad.
        </p>
        <p>
          I also think we have a tendency to stick to our decisions once they've been made—in making a choice it's like we're collapsing
          a wave function and constructing a new set of probabilities. The odds don't actually change, but in casting a vote for one of them
          I think our perception of the probabilities is changed. While having a skewed perception could be a problem, I think overall this
          is good for us—as a default setting I'd rather have a little too much confidence in my choices than be consumed by doubt.
        </p>
        <p>
          Maybe that's why being a relatively young person in modern times can be such an anxious experience. We're faced with a lot of change and uncertainty,
          and making any decision in that kind of environment is stressful. If you're lucky enough to be able to choose where you live, you have to weigh the odds
          of any place having long term political stability, mild climate change impacts, and good job prospects. If you have or want to have kids, now you have a whole
          new set of questions to layer on.
        </p>

        <p>
          Anyway, we're now in Maine for several weeks and Hurricane Lee is headed towards us. Some things you just can't predict.
          I've included the status page I built last year below, and I'll probably set up the automatic updates again.
        </p>
      </Post>

      <hr
        style={{
          color: 'var(--color-text)',
          backgroundColor: 'var(--color-text',
          height: 5,
          width: '100%',
          borderRadius: '5px'
        }}
      />

      <div>
        <h2>Status updates during Hurricane Ian (and I guess now during Hurricane Lee)</h2>
        <p>Automatic updates are sent every 3 hours (8, 11, 2, 5, etc.)</p>
        <p>In case of loss of power & internet, updates will be sent via SMS on an ad hoc basis.</p>
        <p>All times are EST.</p>
      </div>
      <Updates>
        {nodes.sort((a, b) => a.Timestamp < b.Timestamp).map(x => (
          <Log key={x.ID} data={x} />
        ))}
      </Updates>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(
      filter: {table: {eq: "Updates"}}
      sort: {fields: data___Timestamp, order: DESC}
    ) {
      edges {
        node {
          data {
            ID
            Notes
            Power
            Internet
            SMS
            Timestamp
          }
        }
      }
    }
  }
`

export default HurricanePost
