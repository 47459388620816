import React from 'react';
import styled from 'styled-components';

const Post = styled.div`
  max-width: 600px;
  font-size: calc(1.1875rem);

  h1, h2, h3, h4, h5, h6 {
    line-height: 2rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  li {
    margin: .5rem;
  }
`

export default Post;